import React, { useState } from "react";
import contatcsImg from "../../assests/contacts.png";
import MultiSelect from "./MultiSelect";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
const services = [
  "Business Model Optimization",
  "Digital Marketing Mastery",
  "Search Engine Optimization (SEO)",
];

export default function Quotation({ quotationRef }) {
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState(services);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    name: "",
    email: "",
    phone: "",
    services: "",
  });

  function onSubmit(values) {
    setIsLoading(true);
    let newValues = { ...values, services: values.services.join(" - ") };

    emailjs
      .send(
        "service_3jbv3m2",
        "template_qvb4zy4",
        newValues,
        "di_mWn7Hln51R9pde"
      )
      .then(
        () => {
          toast.success("your quotation has been sent successfully", {
            position: "bottom-right",
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#2596be",
              secondary: "#fff",
            },
          });
          reset();
          setSelected([]);
          setList(services);
          setIsLoading(false);
        },
        (error) => {
          toast.error(
            "failed to send your quotation .. please try again later",
            {
              position: "bottom-right",
            }
          );
          console.log(error);
          setIsLoading(false);
        }
      );
  }

  return (
    <>
      <div
        ref={quotationRef}
        className="relative flex items-center justify-center w-full bg-fixed bg-left-bottom bg-no-repeat bg-cover lg:p-32 sm:p-8"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0, 0, 0, 0.7)),url(${contatcsImg})`,
        }}>
        {/* <div className="absolute inset-0 flex items-center justify-center bg-black/70"> */}
        <div className="px-8 py-16 m-auto bg-white shadow-xl md:w-4/5 sm:px-16">
          <div className="grid gap-12 grid-cols lg:grid-cols-2">
            <div>
              <h2 className="text-2xl font-semibold capitalize">
                request for quotation
              </h2>
              <p className="my-6">
                Ready to take your business to new heights? Fill out our
                quotation form, and our dedicated team will get in touch with
                you to discuss your specific requirements and provide a
                customized solution that perfectly aligns with your goals and
                budget.
              </p>
              <div className="font-semibold">
                <p>info@smashdigitalsolutions.com</p>
                <p>Tel : +971 55 355 7069</p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-5">
                <label htmlFor="name" className="block text-blueColor-500">
                  Name
                </label>
                <input
                  className={`w-full border-b-2 ${
                    errors?.name ? "border-red-600" : "border-blueColor-500"
                  } focus:outline-none`}
                  type="text"
                  name="name"
                  id="name"
                  {...register("name", {
                    required: "*required",
                  })}
                />
                {errors.name && (
                  <div className="text-sm text-red-600">
                    {errors.name?.message}
                  </div>
                )}
              </div>
              <div className="mb-5">
                <label htmlFor="phone" className="block text-blueColor-500">
                  Phone
                </label>
                <input
                  className={`w-full border-b-2 ${
                    errors?.phone ? "border-red-600" : "border-blueColor-500"
                  } focus:outline-none`}
                  type="text"
                  name="phone"
                  id="phone"
                  {...register("phone", {
                    required: "*required",
                    pattern: {
                      value:
                        /^(?:00971|\+971|0)?(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/,
                      message: "invalid phone number",
                    },
                  })}
                />
                {errors.phone && (
                  <div className="text-sm text-red-600">
                    {errors.phone?.message}
                  </div>
                )}
              </div>
              <div className="mb-5">
                <label htmlFor="email" className="block text-blueColor-500">
                  Email
                </label>
                <input
                  className={`w-full border-b-2 ${
                    errors?.email ? "border-red-600" : "border-blueColor-500"
                  } focus:outline-none`}
                  type="text"
                  name="email"
                  id="email"
                  {...register("email", {
                    required: "*required",
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <div className="text-sm text-red-600">
                    {errors.email?.message}
                  </div>
                )}
              </div>

              <MultiSelect
                selected={selected}
                setSelected={setSelected}
                list={list}
                setList={setList}
                setValue={setValue}
                errors={errors}
                control={control}
                trigger={trigger}
              />

              <button
                className={`px-8 py-3 text-white transition-all border-2 bg-blueColor-500 border-blueColor-500 hover:bg-transparent hover:text-blueColor-500 ${
                  isLoading ? "opacity-50" : "opacity-100"
                }`}
                type="submit"
                disabled={isSubmitting || isLoading}>
                Submit
              </button>
            </form>
          </div>
          <div className="flex justify-center gap-10 mt-10 text-xl">
            <a
              className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-125"
              href="https://www.facebook.com/sdsolutions.ae"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-125"
              href="https://instagram.com/sdsolutions.ae"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-125"
              href="https://wa.me/971553557069"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Toaster />
    </>
  );
}
