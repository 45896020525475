import { useTrail, a } from '@react-spring/web';
import React from 'react';

const FadeTrail = ({ children, delay, inview, className }) => {
  // console.log(delay);
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 1000, friction: 300 },
    opacity: inview ? 1 : 0,
    from: { opacity: 0 },
    delay: delay ? delay : 0,
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={style} className={className}>
          <a.div>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};
export default FadeTrail;
