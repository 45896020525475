import React, { useState } from 'react';
import FadeTrail from '../../utilities/FadeTrail';
import { Waypoint } from 'react-waypoint';
import data from './logos';
export default function ClientsLogos() {
  const [inview, setInview] = useState(false);

  return (
    <>
      <Waypoint onEnter={() => setInview(true)}>
        <div className="container px-1 py-8 m-auto sm:px-12 lg:px-40 backdrop-blur-sm lg:py-28">
          <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3">
            {/* <Trail> */}
            <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <h2 className="text-3xl font-semibold capitalize ">
                our esteemed clients
              </h2>
            </div>
            {data.slice(0, 5).map((logo, index) => {
              return (
                <div
                  className="flex items-center justify-center p-5 border sm:p-10 border-blueColor-100"
                  key={logo.alt}
                >
                  <FadeTrail inview={inview} delay={index * 200}>
                    <a href={logo.link} target="_blank" rel="noreferrer">
                      <img src={logo.src} alt={logo.alt} />
                    </a>
                  </FadeTrail>
                </div>
              );
            })}

            {/* <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <FadeTrail inview={inview}>
                <img src={logo} alt="logo" />
              </FadeTrail>
            </div>
            <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <FadeTrail delay={300} inview={inview}>
                <img src={logo} alt="logo" />
              </FadeTrail>
            </div>
            <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <FadeTrail delay={600} inview={inview}>
                <img src={logo} alt="logo" />
              </FadeTrail>
            </div>
            <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <FadeTrail delay={900} inview={inview}>
                <img src={logo} alt="logo" />
              </FadeTrail>
            </div>
            <div className="flex items-center justify-center p-5 border border-blueColor-100">
              <FadeTrail delay={1200} inview={inview}>
                <img src={logo} alt="logo" />
              </FadeTrail>
            </div> */}
            {/* </Trail> */}
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3">
            <div className="flex items-center justify-center col-span-2 p-5 border border-blueColor-100">
              We are proud to have worked with a diverse range of clients,
              including startups and medium-sized businesses across various
              industries. Our commitment to delivering exceptional results has
              earned us the trust and loyalty of our clients, who have achieved
              remarkable growth and success through our partnership{' '}
            </div>
            {data.slice(5, 9).map((logo, index) => {
              return (
                <div
                  className="flex items-center justify-center p-5 border sm:p-10 border-blueColor-100"
                  key={logo.alt}
                >
                  <FadeTrail inview={inview} delay={(index + 5) * 200}>
                    <a href={logo.link} target="_blank" rel="noreferrer">
                      <img
                        src={logo.src}
                        alt={logo.alt}
                        className="object-cover"
                        // width={70}
                        // height={70}
                        width={logo.resize ? 70 : ''}
                        height={logo.resize ? 70 : ''}
                      />
                    </a>
                  </FadeTrail>
                </div>
              );
            })}
          </div>
        </div>
      </Waypoint>
    </>
  );
}
