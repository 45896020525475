import { useTrail, a } from '@react-spring/web';
import React from 'react';

const SlideTrail = ({ children, delay, inview }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 3, tension: 1000, friction: 200 },
    opacity: inview ? 1 : 0,
    x: inview ? 0 : 200,
    from: { opacity: 0, x: 200 },
    delay: delay ? delay : 0,
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};
export default SlideTrail;
