import React, { useState } from 'react';
import FadeTrail from '../utilities/FadeTrail';
import { Waypoint } from 'react-waypoint';

export default function AboutUs() {
  const [inview, setInview] = useState(false);

  return (
    <>
      <div className="pt-2 pb-20 backdrop-blur-sm">
        <Waypoint onEnter={() => setInview(true)}>
          <div
            className="px-1 py-24 text-center text-black xl:text-white sm:px-24"
            style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
          >
            {/* <div>
            <img src={search} alt="about-us" />
          </div> */}
            {/* <div> */}
            <h2 className="mb-6 text-3xl font-semibold capitalize">about us</h2>
            <FadeTrail inview={inview} delay={300}>
              <p className="text-lg">
                At Smash Digital Solutions (SDS), we are passionate about
                driving the growth and success of startup and medium-sized
                businesses. With our innovative strategies and expert guidance,
                we empower our clients to unleash their full potential and
                achieve extraordinary results in the competitive business
                landscape.
              </p>
            </FadeTrail>

            {/* </div> */}
          </div>
        </Waypoint>
      </div>
    </>
  );
}
