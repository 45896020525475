// import ClientsDesc from './components/ClientsDesc/ClientsDesc';
import ClientsLogos from './components/ClientsLogo/ClientsLogos';
import Quotation from './components/Qutation/Quotation';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services/Services';
import { useRef } from 'react';

function App() {
  const quotationRef = useRef(null);

  return (
    <>
      <div className="overallImage">
        <Home quotationRef={quotationRef} />
        <AboutUs />
        <Services />
        <ClientsLogos />
        {/* <ClientsDesc /> */}
        <Quotation quotationRef={quotationRef} />
        <Footer />
      </div>
    </>
  );
}

export default App;
