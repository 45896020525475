import React, { useState } from 'react';
import logo from '../assests/logo.png';
import scrollEffect from '../utilities/scroll';
import SlideTrail from '../utilities/SlideTrail';
import { Waypoint } from 'react-waypoint';

// import search from '../assests/search.png';

export default function Home({ quotationRef }) {
  const [inview, setInview] = useState(false);
  return (
    <>
      <div className="absolute top-0 z-30 flex justify-between w-full px-10 bg-white">
        <img src={logo} alt="logo" className="w-32" />
        <div className="flex items-center text-lg gap-x-5">
          <a
            className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-150 animate-bounce "
            href="https://www.facebook.com/sdsolutions.ae"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-150 animate-bounce "
            href="https://instagram.com/sdsolutions.ae"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            className="transition-all cursor-pointer hover:text-blueColor-500 hover:scale-150 animate-bounce "
            href="https://wa.me/971553557069"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
      <div className="relative flex h-screen overflow-hidden backdrop-blur-sm ">
        <div className="absolute top-0 px-10 py-5 text-center"></div>
        <Waypoint onEnter={() => setInview(true)}>
          <div className="flex items-center self-center">
            <div className="p-10">
              <SlideTrail inview={inview}>
                <h1 className="text-5xl font-extrabold text-white uppercase mb-7">
                  Smash Digital Solutions
                </h1>
                <p className="text-xl font-bold text-white capitalize">
                  Ignite Your Business Potential and Achieve Extraordinary
                  Success!
                </p>

                <button
                  className={`px-8 py-3 text-white transition-all border-2 bg-blueColor-500 border-blueColor-500 hover:bg-transparent hover:text-blueColor-500 my-6 capitalize animate-pulse`}
                  onClick={() => scrollEffect(quotationRef)}
                >
                  request quotation
                </button>
              </SlideTrail>
            </div>
          </div>
        </Waypoint>
      </div>
      {/* <div className="fixed right-0 z-10 hidden p-3 bg-white shadow-lg top-24 rounded-l-md md:flex md:flex-col">
        <a
          className="mb-2 transition-all cursor-pointer hover:text-blueColor-500"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          className="mb-2 transition-all cursor-pointer hover:text-blueColor-500"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          className="transition-all cursor-pointer hover:text-blueColor-500"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
      </div> */}
    </>
  );
}
