import Petszilla from '../../assests/clients/pets-zilla.png';
import sooqanime from '../../assests/clients/SouqAnime.avif';
import twink from '../../assests/clients/twink.webp';
import zenlogo from '../../assests/clients/zenlogo.webp';
import B3 from '../../assests/clients/B3.png';
import blowoutbar from '../../assests/clients/blowoutbar.png';
import InspireYoga from '../../assests/clients/InspireYoga.png';
import groveSalons from '../../assests/clients/groveSalons.png';
import fijiWater from '../../assests/clients/fijiWater.png';

let clientsData = [
  {
    src: Petszilla,
    alt: 'petszills',
    link: 'https://www.pets-zilla.com/',
  },
  {
    src: sooqanime,
    alt: 'sooqanime',
    link: 'https://sooqanime.com/',
  },
  {
    src: twink,
    alt: 'twink',
    link: 'https://www.twiinkstudio.com/',
  },
  {
    src: zenlogo,
    alt: 'zeninteriors',
    link: 'https://www.zeninteriors.net/',
  },
  {
    src: blowoutbar,
    alt: 'blow out bar',
    link: 'https://www.blowoutbar.ae/',
  },
  {
    src: InspireYoga,
    alt: 'inspire me yoga',
    link: 'https://inspiremeyoga.net/',
  },
  {
    src: groveSalons,
    alt: 'the grove salons',
    link: 'https://thegrovesalons.com/',
  },
  {
    src: fijiWater,
    alt: 'fiji water',
    link: 'https://www.fijiwater.com/',
    resize: true,
  },
  {
    src: B3,
    alt: 'burj3',
    link: 'https://burj3.com/',
    resize: true,
  },
];
export default clientsData;
