import React from 'react';
import logo from '../assests/logo.png';

export default function Footer() {
  return (
    <>
      <div className="w-full py-3">
        <div className="flex items-center w-3/4 m-auto">
          <img className="w-24" src={logo} alt="logo" />
          <span className="w-full text-center">
            © 2023 All Rights Reserved.
          </span>
        </div>
      </div>
    </>
  );
}
