import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

export default function MultiSelect({
  list,
  selected,
  setList,
  setSelected,
  setValue,
  errors,
  control,
  trigger,
}) {
  const filteredSelectedServices = useRef([]);
  const filteredServices = useRef([]);

  function handleServicesChange(selectedElement, operation) {
    if (selectedElement !== '') {
      //if operation is add
      if (operation === 'add') {
        //1- add item to selected options to show in spans
        filteredSelectedServices.current.push(selectedElement);

        //2-remove selected item from options list
        filteredServices.current = list.filter((item) => {
          return item !== selectedElement;
        });
      }

      //if operation is remove
      if (operation === 'remove') {
        //1- remove item from selected options to show in spans
        filteredSelectedServices.current = selected.filter((item) => {
          return selectedElement !== item;
        });

        //2-add selected item to options list
        filteredServices.current.push(selectedElement);
      }
      //     console.log(selectedVarieties, filteredSelectedVarieties);
      setSelected(filteredSelectedServices.current);
      setList(filteredServices.current);
      setValue('services', filteredSelectedServices.current);
      trigger('services');
    }
  }

  return (
    <>
      <div className="mb-5">
        <label htmlFor="services" className="block text-blueColor-500">
          Services
        </label>
        <Controller
          control={control}
          name="services"
          rules={{
            validate: {
              required: (value) => {
                if (!value || value?.length <= 0)
                  return '*select at least on service';
                else return true;
              },
            },
          }}
          render={({ field: { onChange, onBlur, name } }) => (
            <select
              className={`w-full border-b-2 ${
                errors?.services ? 'border-red-600' : 'border-blueColor-500'
              } focus:outline-none  text-blueColor-500`}
              defaultValue=""
              onBlur={onBlur}
              name={name}
              onChange={(e) => {
                onChange(e);
                handleServicesChange(e.target.value, 'add');
              }}
            >
              <option value="">select an option...</option>
              {list.map((service) => {
                return (
                  <option key={service} value={service}>
                    {service}
                  </option>
                );
              })}
            </select>
          )}
        />
        {errors.services && (
          <div className="text-sm text-red-600">{errors.services?.message}</div>
        )}
        <div className="mt-2">
          {selected.map((item, index) => {
            return (
              <span
                key={index}
                className="inline-block p-2 m-1 text-sm text-white rounded-full bg-blueColor-900"
              >
                {item}
                <i
                  className="ml-1 cursor-pointer fas fa-times fa-xs"
                  onClick={(e) => {
                    handleServicesChange(item, 'remove');
                  }}
                ></i>
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
}
