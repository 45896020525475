import React from 'react';
import businessOptimizationServices from '../../assests/services/business-model.png';
import digitalMarketingSolutions from '../../assests/services/search-engine-optimization.png';
import consultationServices from '../../assests/services/discussion.png';
import salesAndRevenueBoosting from '../../assests/services/revenue.png';
import developmentServices from '../../assests/services/app-development.png';

export default function Services() {
  return (
    <>
      <div className="px-1 py-16 text-center text-black sm:px-24 bg-blueColor-900">
        <h2 className="text-3xl font-semibold text-white capitalize mb-14">
          our services
        </h2>
        <div className="grid grid-cols-1  md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3  text-left gap-y-10 gap-x-5">
          <div className=" p-5 bg-white rounded-lg">
            <img
              src={digitalMarketingSolutions}
              alt="Digital Marketing Solutions"
              className="h-16"
            />
            <h3 className="py-5 text-lg  font-semibold text-blueColor-900">
              Digital Marketing Solutions
            </h3>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Search Engine Optimization (SEO)
            </p>
            <p className="my-2">
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Social Media Marketing
            </p>
            {/* <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Instagram Marketing
            </p> */}
            {/* <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Facebook Marketing
            </p> */}
            {/* </div> */}
            {/* <div> */}
            {/* <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Twitter Marketing
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              TikTok Marketing
            </p>*/}
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Pay-Per-Click Advertising (PPC)
            </p>
          </div>
          <div className="p-5 bg-white rounded-lg ">
            <img
              className="h-16"
              src={consultationServices}
              alt="Consultation Services"
            />
            <h3 className="py-5 text-lg font-semibold text-blueColor-900">
              Consultation Services
            </h3>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Business Strategy Consultation
            </p>
            <p className="my-2">
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Marketing Consultation
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Sales and Revenue Consultation
            </p>
            {/* <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Digital Transformation Consultation
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Financial Consultation
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Operations Consultation
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Project Management Consultation
            </p> */}
          </div>
          <div className="p-5 bg-white rounded-lg">
            <img
              src={salesAndRevenueBoosting}
              alt="Sales and Revenue Boosting"
              className="h-16"
            />
            <h3 className="py-5 text-lg font-semibold text-blueColor-900">
              Sales & Revenue Boosting
            </h3>

            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Tele Marketing
            </p>
            <p className="my-2">
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              WhatsApp Messaging
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              SMS Targeting
            </p>
          </div>
          <div className="p-5 bg-white rounded-lg">
            <img
              src={developmentServices}
              alt="Development Services"
              className="h-16"
            />
            <h3 className="py-5 text-lg font-semibold text-blueColor-900 ">
              Development Services
            </h3>

            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Website Development
            </p>
            <p className="my-2">
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Mobile App Development
            </p>

            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              UI/UX Design Services
            </p>
            {/* <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              API Development and Integration
            </p>

            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Front-end Development
            </p>
            <p>
              <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
              Back-end Development{' '}
            </p> */}
          </div>
          {/* <div className="p-5 bg-white rounded-lg ">
            <img
              src={businessOptimizationServices}
              alt="Business Optimization Services"
            />
            <h3 className="py-3 text-lg font-semibold">
              Business Optimization Services
            </h3>
            <div className="px-5">
              <p>
                <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
                Business Model Optimization
              </p>
              <p className="my-2">
                <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
                Business Model Optimization
              </p>
              <p>
                <i className="mr-2 far fa-lightbulb text-blueColor-900"></i>
                Business Model Optimization
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
